/* img-style */
.img-style {
  img {
    width: 40px;
    height: 40px;
  }
}
.cursor {
  cursor: pointer;
}

/* table */
.table {
  margin-bottom: 0;
  .table-row {
    display: flex;
    align-items: center;

    div {
      padding: 0;
    }
  }
  .table-header {
    font-size: 14px;
    border-bottom: 1px solid var(--grey4);
    display: flex;
    align-items: center;
    padding: 25px 35px;

    div {
      min-width: 85px;
      margin-left: 20px;

      &:first-child {
        width: 75%;
      }
    }
  }
  .table-body {
    padding: 20px 25px 10px;

    .table-row {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .tabletitle {
      width: calc(100% - 100px);
    }

    .icon {
      margin-right: 20px;
    }

    h3 {
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 5px;
    }

    p {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.table-con {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.table-bordered {
  > :not(caption) > * {
    border-color: var(--grey4);
    border-radius: 6px;
  }
}

.mat-elevation-z8 {
  border: 1px solid var(--grey4);

  tr.mat-header-row {
    height: 48px;
  }
}

/* calender-table */
.calender-table {
  td,
  th {
    padding: 10px 15px;

    &:hover {
      cursor: pointer;
      background: var(--grey7);
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    }
  }
}

/* overwrite css */
.material-icons {
  font-family: "Material Icons" !important;
}

/* mat-tab-link  */
.mat-tab-label,
.mat-tab-link {
  font-size: 14px;
  color: var(--grey1);
  opacity: 1 !important;
  font-weight: 400;
  transform: none !important;
  margin-top: 10px !important;
  height: 35px !important;

  &.mat-tab-label-active {
    font-size: 16px;
    font-weight: 500;
  }
}

.mat-tab-nav-bar,
.mat-tab-header {
  border: none;
  &::after {
    content: "";
    position: absolute;
    bottom: 2px;
    width: 100%;
    height: 1px;
    background: var(--grey4);
  }

  p {
    margin-bottom: 0 !important;
  }
}

.mat-tab-list {
  .mat-ink-bar {
    height: 5px !important;
    border-radius: 3px;
  }
}
.multipleChoice {
  .mat-tab-list {
    .mat-ink-bar {
     background-color: #fff !important;
    }
  }
}

.mat-tab-body-content {
  overflow: hidden !important;
}

/* mat-form-field-type-mat-input  */
.mat-form-field {
  width: 100%;
}

.mat-form-field-type-mat-input {
  width: 100%;

  &.mat-form-field-appearance-outline {
    min-height: 40px;

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: 1px;
      }
    }
    .mat-form-field-wrapper {
      padding: 0 !important;
      margin: 0;
    }
    .mat-form-field-outline {
      color: var(--grey4);
      min-height: 40px;
    }
    .mat-form-field-infix {
      padding: 15px 0 12px;
      margin: 0;
      border: none;
    }

    .mat-form-field-subscript-wrapper {
      padding: 0 !important;
      margin-top: 25px;
    }

    .mat-datepicker-toggle {
      top: 4px;
      right: -8px;
      position: relative;
    }
  }
}

/* mat-form-field-type-mat-select */
.mat-form-field-type-mat-select,
.mat-form-field-type-mat-native-select {
  &.mat-form-field-appearance-outline {
    padding: 0;

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: 1px;
      }
    }

    .mat-form-field-outline {
      color: var(--grey4);
      height: 40px;
    }

    .mat-form-field-infix {
      border: none;
      height: 40px;
      padding-top: 17px;
    }
    .mat-form-field-label {
      padding-top: 6px;
      color: var(--grey1);
      font-weight: 400;
      display: none !important;
    }

    .mat-form-field-wrapper {
      margin: 0 !important;
      overflow: hidden;
      height: 40px;
    }

    .mat-form-field-outline-gap {
      display: none;
    }

    .mat-input-element {
      padding: 0;
      margin: 4px 0 0;
      height: 40px;
      border: none;
    }

    .mat-select-arrow-wrapper {
      background: url("/assets/images/icons/down-arrow.svg") no-repeat center
        center;
      width: 15px;
      height: 15px;
      transform: none;
      display: inline-block;

      .mat-select-arrow {
        display: none;
      }
    }
  }
}

/* mat-form-field-type-mat-chip-list */
.mat-form-field-type-mat-chip-list {
  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: var(--grey4);
    }
    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: 1px;
      }
    }
  }
  .mat-form-field-infix {
    padding-top: 5px;

    .mat-chip {
      font-weight: 400;

      &.mat-standard-chip {
        background: var(--grey6);
      }
    }

    .mat-chip-remove {
      position: absolute;
      right: -7px;
      top: -10px;
      opacity: 1;
    }
  }
}

/* searchBox */
.searchBox {
  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      display: flex;
      align-items: center;
      padding: 12px 0 6px;
      min-height: 40px;
    }
    .mat-input-element {
      padding-right: 15px;
    }
  }
  .mat-icon {
    background: url("/assets/images/icons/search.svg") no-repeat center bottom;
    font-family: initial !important;
    text-indent: -999px;
    overflow: hidden;
    cursor: pointer;
  }
}

/*  */
.input-flex {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey4);
  border-radius: 5px;
  height: 40px;
  overflow: hidden;

  .mat-form-field {
    width: 60px;
    border-right: 1px solid var(--grey4);
    height: 38px;

    &.mat-form-field-type-mat-select {
      &.mat-form-field-appearance-outline {
        .mat-form-field-outline {
          color: var(--white);
        }
      }
    }
  }

  .mat-form-field-autofill-control {
    width: calc(100% - 65px);
    height: 40px;
    padding: 0 10px;
  }
}

/* number arrow hide*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* action */
.action {
  button {
    width: 150px;
    height: 40px;
    background: none;
    // padding: 5px 20px;
    border: 1px solid var(--blue2);
    border-radius: 5px;
    font-weight: 500;
    line-height: 36px;
  }
}

/* add-shadow */
.add-shadow {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px var(--grey4);
  }
}

/* fiterbox */
.filterStyle {
  width: 30px;
}

.fiterbox {
  position: relative;
  z-index: 1;
  background: var(--white);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px 25px 20px 10px;
  display: flex;
  margin-top: -20px;

  .fiterbox-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .fiterbox-card {
      width: calc(20% - 27px);
      margin: 0 15px;

      .mat-select {
        position: absolute;
      }

      &.btn-group {
        margin-right: 0;
        display: flex;
        justify-content: flex-end;
        padding-top: 25px;

        .theme-btn {
          margin: 0 5px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 15px 20px 20px;
    margin-top: -70px;

    .fiterbox-row {
      .fiterbox-card {
        width: 100%;
        margin: 0 0 15px;

        &.btn-group {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

/*  mat-table */
.mat-table {
  width: 100%;
  tr {
    &:hover {
      -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),
        0 2px 10px 0 rgb(0 0 0 / 12%);
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
      background: var(--grey9);
    }
  }
}

.mat-header-cell {
  font-size: 14px;
  color: var(--grey1);
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-color: var(--grey4);
  vertical-align: middle;
}

/* table */
table {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);

  tr {
    &:hover {
      -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),
        0 2px 10px 0 rgb(0 0 0 / 12%);
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
      background: var(--grey9);
    }
  }
}

::ng-deep .table-style {
  table {
    box-shadow: none !important;
  
    tr {
      &:hover {
        box-shadow: none !important;
        background: inherit !important;
      }
    }
  }
}

/* mat-calendar-table */
.mat-calendar-table {
  box-shadow: none;

  tr {
    &:hover {
      box-shadow: none;
      background: inherit;
    }
  }
}

/* mat-slide-toggle */
.mat-slide-toggle {
  margin: 0 10px;

  &.mat-checked {
    .mat-slide-toggle-bar {
      background: var(--blue1);
    }
    .mat-slide-toggle-thumb {
      background: var(--blue2);
    }
  }
}
/* popupStyle */

.cdk-overlay-pane {
  @media screen and (max-width: 767px) {
    max-width: calc(100% - 40px) !important;
  }
}
.popup-section {
  margin: -24px;
  overflow: hidden;
  .popup-header {
    padding: 20px 20px 15px;
    border-bottom: 1px solid var(--grey4);

    h3 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }

  .popup-body {
    padding: 25px 30px 35px;
  }

  table {
    box-shadow: none;
    width: 100%;

    tr {
      &:hover {
        box-shadow: none;
        background: inherit;
      }
    }
  }

  .popup-footer {
    border-top: 1px solid var(--grey4);
    padding: 10px 20px;

    .btn-group {
      justify-content: flex-end;
      display: flex;
    }
  }

  @media screen and (max-width: 767px) {
    .popup-body {
      padding: 20px;
    }
  }
}

.sucess-popup,
.fail--popup {
  min-width: 500px;

  .popup-header {
    padding: 10px;

    .mat-dialog-title {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }

  .popup-body {
    padding: 10px;
  }
}

.sucess-popup {
  .popup-header {
    background-color: green;
    color: var(--white);
  }
}

.fail--popup {
  .popup-header {
    background-color: var(--red);
    color: var(--white);
  }
}

.sucess-popup,
.fail--popup {
  min-width: 500px;

  .popup-header {
    padding: 10px;

    .mat-dialog-title {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }

  .popup-body {
    padding: 10px;
  }
}

.sucess-popup {
  .popup-header {
    background-color: green;
    color: var(--white);
  }
}

.fail--popup {
  .popup-header {
    background-color: var(--red);
    color: var(--white);
  }
}

/* widget_bg */
.widget_bg {
  background: var(--grey7);
  padding: 20px;
  overflow: hidden;
  border-radius: 5px;
}

/* radio-group  */
.radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .radio {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;

  .theme-btn {
    margin-left: auto;
  }

  @media screen and (max-width: 767px) {
    .radio-group {
      margin-bottom: 10px;

      .radio {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .theme-btn {
      margin-left: 0;
    }
  }
}

/*  */
@media screen and (max-width: 767px) {
  .float-btn-botton {
    position: fixed !important;
    bottom: 90px !important;
    right: 20px !important;
    z-index: 111 !important;
    box-shadow: 2px 2px 12px var(--grey4) !important;
    min-width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    padding: 0 !important;
    text-indent: -999px !important;
    overflow: hidden !important;

    &::after {
      content: "+" !important;
      position: absolute !important;
      width: 50px !important;
      height: 50px !important;
      line-height: 50px !important;
      display: var(--white) !important;
      top: 0 !important;
      left: 0 !important;
      font-size: 30px !important;
      text-indent: 0 !important;
      font-weight: 300 !important;
    }
  }
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--blue2) !important;
}
.addMr {
  margin-left: 25px;
}
@media screen and (max-width: 991px) {
  .addMr {
    margin-left:  0 !important;
  }
}
.align-items-center {
  align-items: center;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .performace-component {
    .col-lg-2 {
      width: 20.666667% !important;
    }
    .col-lg-3 {
      width: 35% !important;
    }
  }  
}

.align-center {
  align-items: center;
}
.align-item-baseline {
  align-items: baseline;
}
.public-background-wrapper {
  position: absolute;
  top: 0;
  z-index: -1;
  background-color: var(--blue4);
  width: 100%;
  height: 70%;
  img {
    width: 100%;
  }
}
.f-green {
  color: var(--green);
}
.bold {
  font-weight: bold;
}
.public-bg-wrapper {
  border-radius: 10px;
  padding: 10px;
}
.d-block {
  display: block;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}

.f_white {
  color: white !important;
}
.f-l_green {
  color: #5aff7e;
}
.text-center {
  text-align: center;
}

.hud_details {
  color: white;
  text-align: left;
  padding-left: 3%;
  padding-top: 2%;
}
@media screen and (max-width: 768px) {
  .hud_details {
    h3 {
      display: inline-block;
    }
  }
  .hud_details_mobView {
    .row > * {
      width: 0px !important;
    }
  }  
}

.searchBox1 {
  display: flex;
  background-color: #ffff;
  padding: 1%;
  border-radius: 100px;
  box-shadow: 0px 3px 30px rgba(153, 186, 208, 0.3);
  height: 40px;
  input {
    border: none;
    width: 100%;
    margin-left: 10px;
  }
  input:focus {
    outline: none;
  }
}

.ui-update {
  .outline-border {
    .mat-form-field-type-mat-select.mat-form-field-appearance-outline {
      box-shadow: 0px 3px 30px rgba(153, 186, 208, 0.3) !important;
      color: transparent !important;
    }
    .mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent !important;
    }
    .mat-form-field-type-mat-input.mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent !important;
      box-shadow: 0px 3px 30px rgba(153, 186, 208, 0.3) !important;
    }
  }
  .filterStyle {
      width: 50px;
      height: 50px;
      display: flex;
      background: white;
      padding: 13px;
      border-radius: 30px;
      box-shadow: 0px 3px 30px rgb(153 186 208 / 30%);
      margin-top: 13px;
  }
  .fiterbox {
    width: calc(100% - 7%) !important;
  }
}

@media screen and (max-width: 1200px) {
  .public-background-wrapper { 
    height: 100%;
  }
}
@media screen and (max-height: 1100px) {
  .public-background-wrapper { 
    height: 90%;
  }
}
.border_red{
  border: 1px solid red;
}
.select_error{
  ::ng-deep{
    .mat-form-field-type-mat-select.mat-form-field-appearance-outline .mat-form-field-outline, .mat-form-field-type-mat-native-select.mat-form-field-appearance-outline .mat-form-field-outline{
      border: 1px solid red;
    }
  }
}